<template>
  <div
    class="search"
    ref="scrollTarget"
  >
    <Banner
      ref="banner"
      title="SEARCH"
      subtitle="搜尋產品"
      :img="bannerImg"
    />
    <div class="container w1400">
      <div class="page_title">
        <div class="title_wrapper">
          <div class="title h3 txt-main"><span class="suffix-redCube">SEARCH RESULT</span></div>
          <div class="subtitle large">搜尋結果</div>
        </div>
        <div class="filter_wrapper">
          <Input
            :format="inputFormat.keywordInput"
            v-model.trim="keyword"
            @keyup.enter.native="onSearch"
            @cleanValue="cleanKeyword"
            @composingStatus="updateComposingStatus"
            class="filter_keyword"
          />
          <button @click="onSearch">
            <Search strokeColor="#fff" />
          </button>
        </div>
      </div>

      <template v-if="products.length">
        <div class="product_list">
            <ProductItem
              v-for="(product, idx) in products"
              :key="`product_${idx}`"
              :series="{ main: product.lv1, sub: product.lv2 }"
              :product="product"
            />
        </div>

        <Pagination
          :page="currentPage"
          :totalPage="totalPage"
          @updatePage="updatePage"
        />
      </template>
      <template v-else>
        <div class="no_result h4 txt-main txt-center">查無結果，請重新搜尋</div>
      </template>
    </div>
    <PdfDownloader
      :class="{ fixed: isFixed }"
    />
  </div>
</template>

<script>
import '@/assets/scss/search.scss';
import bannerImg from '@/assets/imgs/banner/banner_search.png';
import Banner from '@/components/banner/Index.vue';
import Input from '@/components/form/Input.vue';
import Search from '@/components/icon/Search.vue';
import ProductItem from '@/components/productItem/Index.vue';
import Pagination from '@/components/pagination/Index.vue';
import PdfDownloader from '@/components/pdfDownloader/Index.vue';

import { isEqual } from '@/lib/lodash';
import requestApi from '@/lib/http';
import { formatProduct } from '@/utils/formatResponse';

export default {
  name: 'SearchIndex',
  components: {
    Banner,
    Input,
    Search,
    ProductItem,
    Pagination,
    PdfDownloader,
  },
  data() {
    return {
      bannerImg,
      composingStatus: false,
      keyword: '',
      products: [],
      totalPage: 0,
      isFixed: true,
    };
  },
  computed: {
    headerKeyword() {
      return this.$store.state.site.searchKeyword;
    },
    inputFormat() {
      return {
        keywordInput: {
          type: 'text',
          placeholder: '請輸入產品名稱或型號',
        },
      };
    },
    currentPage() {
      return Number(this.$route?.query?.page, 10) || 1;
    },
  },
  methods: {
    cleanKeyword() {
      this.keyword = '';
      this.getData({}, 1);
    },
    onSearch() {
      if (this.composingStatus) {
        this.composingStatus = false;
        return;
      }
      this.getData({
        keyword: this.keyword,
      }, 1);
    },
    onHeaderSearch() {
      const queryData = { keyword: '' };
      if (this.headerKeyword) {
        queryData.keyword = this.headerKeyword;
        this.keyword = this.headerKeyword;
      }
      this.getData(queryData, 1);
    },
    updatePage(page) {
      if (!isEqual(Number(this.$route.query.page, 10), Number(page, 10))) {
        this.$router.push({ query: { page } });
      }
    },
    async getData(payload, page = 1) {
      const { keyword } = payload;

      const perPage = 12;
      const skip = (page - 1) * perPage;

      const { entries: data, total } = await requestApi('common.getProducts', {
        filter: {
          $or: {
            '': {
              title: { $regex: keyword },
              title_eng: { $regex: keyword },
              pro_no: { $regex: keyword },
            },
          },
        },
        limit: perPage, // 每頁幾筆資料
        skip, // 跳過幾筆資料後開始顯示
      });
      this.products = data.map(formatProduct);
      this.totalPage = Math.ceil(total / perPage);
    },
    scrollHandler() {
      const screenHeight = window.screen.height;
      const bannerHeight = this.$refs.banner.$el.clientHeight;
      const targetHeight = this.$refs.scrollTarget.getBoundingClientRect().height;
      if (window.scrollY + (screenHeight * 1.05) >= bannerHeight + targetHeight) {
        this.isFixed = false;
      } else {
        this.isFixed = true;
      }
    },
    updateComposingStatus(status) {
      this.composingStatus = status;
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  mounted() {
    /** 每次進頁面將頁數重置 */
    this.updatePage(1);

    /** 使用 Header 搜尋欄輸入的關鍵字查詢結果 */
    this.onHeaderSearch();
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  watch: {
    /** 換頁時，使用 頁碼 及 頁面內關鍵字 查詢結果 */
    $route: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          const { page } = newVal.query;

          const queryData = {};
          if (this.keyword) queryData.keyword = this.keyword;

          this.getData(queryData, Number(page, 10));
        }
      },
    },
    headerKeyword: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          if (newVal) {
            this.onHeaderSearch();
          }
        }
      },
    },
  },
};
</script>
